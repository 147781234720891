import { FC } from "react";
import { SCMProject } from "./Projects";
import { CodeBracketIcon, TrashIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { useProjectRemove } from "./useProjectRemove";

export const ProjectItem: FC<{ project: SCMProject }> = ({ project }) => {
  const remove = useProjectRemove();
  return (
    <li
      key={project.id}
      className="col-span-1 divide-y divide-gray-200 dark:divide-gray-700 rounded-lg bg-white dark:bg-gray-800 shadow"
    >
      <div className="flex w-full items-center justify-between space-x-6 p-6 pb-2">
        <div className="flex-1 truncate">
          <div className="flex items-center space-x-3">
            <h3 className="truncate text-sm font-medium text-gray-900 dark:text-gray-100">
              {project.name}
            </h3>
            {project.shared_with && (
              <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-100 dark:bg-green-900 px-1.5 py-0.5 text-xs font-medium text-green-800 dark:text-green-200 ring-1 ring-inset ring-green-600/20 dark:ring-green-500/30">
                Shared
              </span>
            )}
          </div>
          <p className="mt-1 truncate text-sm font-light text-gray-600 dark:text-gray-400">
            {project.description}
          </p>
        </div>
        <div className="flex-shrink-0 text-xs text-gray-500 dark:text-gray-400">
          #{project.id}
        </div>
      </div>
      <div>
        <div className="-mt-px flex divide-x divide-gray-200 dark:divide-gray-700">
          <Link
            className="flex w-0 flex-1 text-xs p-4 hover:bg-gray-50 dark:hover:bg-gray-700/50 cursor-pointer justify-center flex-row text-gray-600 dark:text-gray-300 rounded-bl-lg"
            to={`/project/${project.id}`}
          >
            <CodeBracketIcon
              className="h-5 w-5 text-gray-500 dark:text-gray-400 inline mr-2"
              aria-hidden="true"
            />
            View {project.embeds} scripts
          </Link>
          <button
            className="-ml-px flex w-0 flex-1 text-xs p-4 text-red-600 dark:text-red-400 hover:bg-red-50 dark:hover:bg-red-900/20 justify-center flex-row rounded-br-lg"
            onClick={() => {
              remove(project.id);
            }}
          >
            <TrashIcon
              className="h-5 w-5 text-red-500 dark:text-red-400 inline mr-2"
              aria-hidden="true"
            />
            Delete
          </button>
        </div>
      </div>
    </li>
  );
};

export const ProjectList: FC<{ projects: SCMProject[] }> = ({ projects }) => {
  return (
    <ul className="grid grid-cols-1 gap-2 xl:gap-12 xl:grid-cols-2">
      {projects.map((project) => (
        <ProjectItem key={project.id} project={project} />
      ))}
    </ul>
  );
};
