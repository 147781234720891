import {
  FC,
  useState,
  useEffect,
  useRef,
  Fragment,
  useMemo,
  ElementRef,
} from "react";
import MonacoEditor, { useMonaco } from "@monaco-editor/react";
import clipboardCopy from "clipboard-copy";
import {
  EmbedData,
  useBase64Uri,
  useEmbed,
  useShare,
  useShareLink,
} from "./EmbedProviderBase";
import LanguageSwitch from "./LanguageSwitch";
import { useHome } from "./HomeBase";
import SidebarPortal from "./SidebarPortal";
import { toast } from "react-toastify";
import { SHA256 } from "crypto-js";
import { useDebounce } from "./useDebounce";
import {
  ClipboardDocumentIcon,
  ClipboardIcon,
  CloudArrowDownIcon,
  CloudArrowUpIcon,
  CodeBracketSquareIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  FolderPlusIcon,
  HeartIcon,
  LinkIcon,
  LockClosedIcon,
  LockOpenIcon,
  PaperClipIcon,
  PencilIcon,
  TrashIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { useAuthenticatedFetch, useAuthenticatedQuery } from "./Authenticator";
import { useNavigate } from "react-router-dom";
import { useRemove } from "./useRemove";
import { format, check } from "prettier";
import prettierBabel from "prettier/parser-babel";
import prettierCss from "prettier/parser-postcss";
import prettierHtml from "prettier/parser-html";
import { useModalPicker } from "./ModalPicker";
import { SCMProject } from "./Projects";
import { useMoveScript } from "./useMoveScript";
import { saveAs } from "file-saver";
import { Uri } from "monaco-editor";
import WewebEmbed from "./WewebEmbed";
import EmbedTag from "./EmbedTag";
import PartyKitEditor from "./PartyKitEditor";
import EmbedRawUrl from "./EmbedRawUrl";
import { XanoClient } from "@xano/js-sdk/lib/client";
import { useIsDark } from "./DarkModeProvider";
import { SidebarItem, SidebarLabel } from "./catalyst/sidebar";
import { Fieldset, Field, Label } from "./catalyst/fieldset";
import { Input } from "./catalyst/input";
// import type { XanoClient as XanoClientType } from "@xano/js-sdk";
let xanoChannel: any | undefined;
const Editor: FC = () => {
  const {
    body,
    data,
    isLoading,
    save,
    saving,
    setType,
    type,
    unsaved,
    name,
    setBody,
    setName,
    setIsShared,
    isShared,
    setLoaded,
    refetch,
    description,
    setDescription,
  } = useEmbed();
  const isDark = useIsDark();
  const isPK = !!parseInt(localStorage.getItem("isPK") || "0");
  // console.log("I am a PK", isPK);
  const [isEditing, setIsEditing] = useState(true);
  const rootRef = useRef<HTMLDivElement>(null);
  const [height, setHeight] = useState(
    window.innerHeight - (rootRef.current?.offsetTop || 0)
  );
  useEffect(() => {
    setTimeout(() => {
      setHeight(window.innerHeight - (rootRef.current?.offsetTop || 0));
    }, 500);
  }, []);
  const [width, setWidth] = useState(0);
  const projectData = data as { project: SCMProject } | undefined;
  const project = projectData?.project;
  const sharedWidth = project?.shared_with || [];
  const sharedWidthLength = sharedWidth.length;
  useEffect(() => {
    if (!sharedWidthLength) return;
    setIsEditing(false);
  }, [sharedWidthLength]);
  //@TODO RHD
  // const monaco = useMonaco()
  // monaco?.getEditor().getPosition()
  useEffect(() => {
    if (!rootRef.current || !rootRef.current.parentElement?.parentElement)
      return;
    const w = () => {
      console.log(
        "I am resizing",
        rootRef.current?.offsetTop,
        rootRef.current?.parentElement?.offsetTop
      );
      setHeight(window.innerHeight - (rootRef.current?.offsetTop || 0));
    };
    window.addEventListener("resize", w);
    // const ho = new ResizeObserver((entries) => {
    //   console.log("Height detected", entries[0].contentRect.height);
    // setHeight(
    //   entries[0].contentRect.height -
    //     rootRef.current.parentElement.parentElement.offsetTop
    // );
    // });
    // ho.observe(window.document.body);
    const wo = new ResizeObserver((entries) => {
      // console.log("Width detected", entries[0]);
      const newWidth = entries[0].contentRect.width; // - entries[0].contentRect.left;
      // console.log("I will set width to ", newWidth);
      setWidth(newWidth);
    });
    wo.observe(rootRef.current.parentElement.parentElement);
    return () => {
      window.removeEventListener("resize", w);
      // ho.disconnect();
      wo.disconnect();
    };
  }, [data]);
  useEffect(() => {
    const windowWidth = window.innerWidth;
    const leftPos = rootRef.current?.getBoundingClientRect().left;
    if (typeof leftPos !== "undefined") {
      if (width > windowWidth - leftPos - 20) {
        setWidth(windowWidth - leftPos - 20);
      }
    }
  }, [width]);
  const [showCompiled, setShowCompiled] = useState(false);
  useEffect(() => {
    if (showCompiled && isEditing) setIsEditing(false);
  }, [showCompiled, isEditing]);
  const { data: compiledData, refetch: refetchCompiled } =
    useAuthenticatedQuery<{
      compiled: string;
    }>(`/me/compiled/${data?.id}`, undefined, !data?.id);
  const refetchRef = useRef(refetchCompiled);
  refetchRef.current = refetchCompiled;
  useEffect(() => {
    if (showCompiled) refetchRef.current();
  }, [showCompiled]);
  const { setTitle } = useHome();
  useEffect(() => {
    if (name)
      setTitle(
        name +
          (type === "javascript"
            ? ".js"
            : type === "css"
            ? ".css"
            : type === "html"
            ? ".html"
            : "") +
          (isEditing ? "" : " (read-only)")
      );
    else if (isLoading) setTitle("");
    else setTitle("Untitled Document" + (isEditing ? "" : " (read-only)"));
  }, [name, setTitle, unsaved, type, isLoading, isEditing]);
  const refetchReference = useRef(refetch);
  refetchReference.current = refetch;
  const isEditingRef = useRef(isEditing);
  isEditingRef.current = isEditing;
  useEffect(() => {
    (async () => {
      // console.log("I am in useEffect for realtime", data?.guid);
      if (!data?.guid) return;
      if (xanoChannel) xanoChannel.destroy();
      const options = {
        instanceBaseUrl: import.meta.env.VITE_XANO_BASE_URL,
        realtimeConnectionHash: import.meta.env.VITE_XANO_RT_HASH,
      };
      // console.log("I am in useEffect for realtime", options);
      const xanoClient = new XanoClient(options);
      const channel = `statechange/${btoa(data?.guid)}`;
      // console.log("Connecting to xanoChannel", channel);
      xanoChannel = xanoClient.channel(channel);

      xanoChannel.on(
        (action: { action: string; payload: Record<string, any> }) => {
          if (action.action === "event") {
            // toast.success("Got a notice from Xano about this changin my boy");
            // console.log("Got payload", action);
            if (!action.payload?.data) return;
            if (!isEditingRef.current || haveSavedRef.current) {
              // toast.success("I will refetch the data");
              refetchReference.current();
            } else {
              // toast.warning("Someone else just made a change to this file!");
            }
          } else {
            // console.log("Got some other message", action);
          }
        }
      );
    })();
  }, [data?.guid]);

  // const intervalRef = useRef<ReturnType<typeof setInterval> | undefined>();
  // useEffect(() => {
  //   if (intervalRef.current) clearInterval(intervalRef.current);
  //   if (!isEditing) {
  //     intervalRef.current = setInterval(() => {
  //       refetch();
  //     }, 5000);
  //   } else {
  //     intervalRef.current = undefined;
  //   }
  //   return () => {
  //     if (intervalRef.current) clearInterval(intervalRef.current);
  //   };
  // }, [isEditing, refetch]);
  const debouncedSave = useDebounce(SHA256(body + type).toString(), 1000);
  const saveRef = useRef(save);
  saveRef.current = save;
  const haveSavedRef = useRef(false);
  const isLoadingRef = useRef(isLoading);
  isLoadingRef.current = isLoading;
  useEffect(() => {
    if (!isEditing) return;
    if (!isLoadingRef.current) {
      if (!haveSavedRef.current) {
        console.log("I will not save because I have not saved yet");
        haveSavedRef.current = true;
        return;
      }
      console.log("I decided to save", debouncedSave);
      saveRef.current();
    }
  }, [debouncedSave, isEditing]);
  const share = useShare();
  const sharedUrl = useShareLink();
  const navigate = useNavigate();
  const fetch = useAuthenticatedFetch();
  const remove = useRemove();
  const moveScript = useMoveScript();
  const checked = useMemo(() => {
    try {
      return (
        check(body.replaceAll("\r\n", "\n"), {
          parser: type === "javascript" ? "babel" : "css",
          plugins: [
            type === "javascript"
              ? prettierBabel
              : type === "css"
              ? prettierCss
              : prettierHtml,
          ],
        }) ||
        check(body.replaceAll("\r\n", "\n") + "\n", {
          parser: type === "javascript" ? "babel" : "css",
          plugins: [
            type === "javascript"
              ? prettierBabel
              : type === "css"
              ? prettierCss
              : prettierHtml,
          ],
        })
      );
    } catch (e) {
      return false;
    }
  }, [body, type]);
  const pick = useModalPicker();
  const monaco = useMonaco();
  useEffect(() => {
    if (monaco) {
      monaco.editor
        .getModel(new Uri())
        ?.setEOL(monaco.editor.EndOfLineSequence.LF);
    }
  }, [monaco]);
  const editorRef = useRef<ElementRef<typeof PartyKitEditor>>(null);
  const getBase64 = useBase64Uri();
  if (!data) return null;
  // if (!width) return null;
  return (
    <div
      className="relative w-full"
      ref={rootRef}
      style={{
        height: height - 25,
        width: width,
        left: 0,
      }}
    >
      <SidebarPortal>
        <Fieldset className="space-y-4 mx-2">
          <Field>
            <Label>Nickname of document</Label>
            <Input
              value={name}
              onChange={(e) => {
                setName(e.target.value);
                save();
              }}
            />
          </Field>

          <Field>
            <Label>Description</Label>
            <Input
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
                save();
              }}
            />
          </Field>

          <Field>
            <Label>Type of Embed</Label>
            <LanguageSwitch language={type} setLanguage={setType} />
          </Field>
        </Fieldset>

        {!isEditing && (
          <SidebarItem onClick={() => setIsEditing(true)}>
            <PencilIcon className="h-4 w-4" data-slot="icon" />
            <SidebarLabel>Start Editing</SidebarLabel>
          </SidebarItem>
        )}

        {isEditing && (
          <SidebarItem onClick={() => setIsEditing(false)}>
            <XCircleIcon className="h-4 w-4" data-slot="icon" />
            <SidebarLabel>Stop Editing</SidebarLabel>
          </SidebarItem>
        )}

        {body.includes("@scminclude") && (
          <SidebarItem onClick={() => setShowCompiled((o) => !o)}>
            <CodeBracketSquareIcon className="h-4 w-4" data-slot="icon" />
            <SidebarLabel>
              {showCompiled ? "Show Editor" : "Show Compiled Preview"}
            </SidebarLabel>
          </SidebarItem>
        )}

        <SidebarItem
          onClick={async () => {
            const response = await fetch(`/me/embed`);
            const { items: list } = (await response.json()) as {
              items: EmbedData[];
            };
            const filteredList = list.filter(
              (e) => e.type === type && e.id !== data.id
            );
            if (filteredList.length === 0) {
              toast.error("You don't have any other files of this type");
              return;
            }
            const keyString = await pick({
              options: filteredList.map((e) => ({
                title: e.name.split("/").pop() || "Untitled file",
                subtitle: e.name + " (" + e.id + ")",
                key: e.id.toString(),
              })),
              title: "Pick a file to embed",
              message: "Select one of your files to include in this package",
            });
            if (type === "javascript") {
              if (isPK) {
                editorRef.current?.prepend(
                  "//@scminclude " + keyString + " " + name + "\n"
                );
              } else {
                setBody(
                  "//@scminclude " + keyString + " " + name + "\n" + body
                );
              }
            } else if (type === "css") {
              if (isPK) {
                editorRef.current?.prepend(
                  "/* @scminclude " + keyString + " " + name + " */\n"
                );
              } else {
                setBody(
                  "/* @scminclude " +
                    keyString +
                    " " +
                    name +
                    " */" +
                    "\n" +
                    body
                );
              }
            }
            toast.success("Added the include statement");
          }}
        >
          <PaperClipIcon className="h-4 w-4" data-slot="icon" />
          <SidebarLabel>
            Include {body.includes("@include") ? "another" : "a"} file
          </SidebarLabel>
        </SidebarItem>

        <SidebarItem
          onClick={async () => {
            if (isPK) {
              editorRef.current?.prettify();
            } else {
              try {
                const newBody = await format(body.replaceAll("\r\n", "\n"), {
                  parser:
                    type === "javascript"
                      ? "babel"
                      : type === "css"
                      ? "css"
                      : "html",
                  plugins: [prettierBabel, prettierCss, prettierHtml],
                });
                setBody(newBody);
                if (
                  await check(newBody, {
                    parser:
                      type === "javascript"
                        ? "babel"
                        : type === "css"
                        ? "css"
                        : "html",
                    plugins: [prettierBabel, prettierCss, prettierHtml],
                  })
                ) {
                  toast.success("Prettified code!");
                } else {
                  toast.error("Prettified code, but it's still invalid");
                }
              } catch (e) {
                console.error(e);
                toast.error("Failed to prettify: " + (e as Error).message);
              }
            }
          }}
        >
          <HeartIcon className="h-4 w-4" data-slot="icon" />
          <SidebarLabel>Prettify code</SidebarLabel>
        </SidebarItem>

        <EmbedTag />
        <EmbedRawUrl />
        <WewebEmbed />

        <SidebarItem
          onClick={() => {
            navigator.clipboard.writeText(getBase64());
            toast.success("Copied base64 data URI to clipboard");
          }}
        >
          <ClipboardDocumentIcon className="h-4 w-4" data-slot="icon" />
          <SidebarLabel>Copy Base64 data URI</SidebarLabel>
        </SidebarItem>

        <SidebarItem
          onClick={() => {
            clipboardCopy(body);
            toast.success("Copied text to clipboard");
          }}
        >
          <ClipboardIcon className="h-4 w-4" data-slot="icon" />
          <SidebarLabel>Copy text to clipboard</SidebarLabel>
        </SidebarItem>

        <SidebarItem
          onClick={async () => {
            const response = await fetch("/me/embed", {
              method: "POST",
              body: JSON.stringify({
                name: name + " (copy)",
                type,
                body,
              }),
            });
            if (response.ok) {
              const data: EmbedData = await response.json();
              toast.success("Copied " + name);
              // console.log("Got back answer of ", data);
              moveScript(data.id, () => {
                setLoaded(false);
                navigate(`/${data.id}`);
                window.location.reload();
              });
            } else {
              toast.error("Failed to clone");
            }
          }}
        >
          <DocumentDuplicateIcon className="h-4 w-4" data-slot="icon" />
          <SidebarLabel>Duplicate</SidebarLabel>
        </SidebarItem>

        {!isShared && (
          <SidebarItem
            onClick={async () => {
              await share();
            }}
          >
            <LockOpenIcon className="h-4 w-4" data-slot="icon" />
            <SidebarLabel>Enable Sharing</SidebarLabel>
          </SidebarItem>
        )}

        {isShared && (
          <Fragment>
            <SidebarItem
              onClick={() => {
                if (sharedUrl) {
                  clipboardCopy(sharedUrl);
                  toast.success("Copied share link to clipboard");
                } else {
                  toast.error("Could not share the document");
                }
              }}
            >
              <LinkIcon className="h-4 w-4" data-slot="icon" />
              <SidebarLabel>Copy Share Link</SidebarLabel>
            </SidebarItem>
            <SidebarItem
              onClick={() => {
                setIsShared(false);
              }}
            >
              <LockClosedIcon className="h-4 w-4" data-slot="icon" />
              <SidebarLabel>Disable Sharing</SidebarLabel>
            </SidebarItem>
          </Fragment>
        )}

        <SidebarItem
          onClick={() => {
            remove(data.id, () => {
              navigate("/");
            });
          }}
        >
          <TrashIcon className="h-4 w-4" data-slot="icon" />
          <SidebarLabel>Delete</SidebarLabel>
        </SidebarItem>

        <SidebarItem
          // className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
          // onClick={() => {
          //   if (project?.id) navigate("/project/" + project?.id);
          //   else navigate("/");
          // }}
          href={project?.id ? "/project/" + project?.id : "/"}
          onClick={() => {
            if (project?.id) navigate("/project/" + project?.id);
            else navigate("/");
          }}
        >
          <FolderIcon className="h-4 w-4" data-slot="icon" />
          <SidebarLabel>{project?.name || "Unassigned"}</SidebarLabel>
        </SidebarItem>

        <SidebarItem
          onClick={async () => {
            moveScript(data.id, refetch);
          }}
        >
          <FolderPlusIcon className="h-4 w-4" data-slot="icon" />
          <SidebarLabel>Move to Another Project</SidebarLabel>
        </SidebarItem>

        <SidebarItem
          onClick={async () => {
            saveRef.current();
          }}
        >
          <CloudArrowUpIcon className="h-4 w-4" data-slot="icon" />
          <SidebarLabel>Save to Cloud</SidebarLabel>
        </SidebarItem>

        <SidebarItem
          onClick={() => {
            saveAs(
              new Blob([body], { type: "plain/text" }),
              name +
                (type === "javascript"
                  ? ".js"
                  : type === "css"
                  ? ".css"
                  : type === "html"
                  ? ".html"
                  : "")
            );
          }}
        >
          <CloudArrowDownIcon className="h-4 w-4" data-slot="icon" />
          <SidebarLabel>Download file</SidebarLabel>
        </SidebarItem>
      </SidebarPortal>
      <div className="flex flex-row w-full justify-end space-x-4">
        {!showCompiled && (
          <Fragment>
            <div className="text-gray-200 px-2">
              <button
                onClick={() => {
                  editorRef.current?.prettify();
                }}
              >
                {checked ? (
                  <span className="px-2 text-xs bg-green-800 rounded-full p-1">
                    Prettified!
                  </span>
                ) : (
                  <span className="px-2 text-xs bg-red-800 rounded-full p-1">
                    Not Prettified
                  </span>
                )}
              </button>
            </div>
            <div className=" text-gray-200">
              <button onClick={save} disabled={!unsaved}>
                {unsaved ? (
                  <span className="text-xs px-2 bg-red-800 rounded-full p-1">
                    Not yet saved
                  </span>
                ) : saving ? (
                  <span className="text-xs px-2 bg-yellow-800 rounded-full p-1">
                    Saving...
                  </span>
                ) : (
                  <span className="text-xs px-2 bg-green-800 rounded-full p-1">
                    Saved on server
                  </span>
                )}
              </button>
            </div>
          </Fragment>
        )}
        {showCompiled && (
          <div className="text-gray-200">
            <button onClick={() => setShowCompiled(false)}>
              <span className="text-xs px-2 bg-green-800 rounded-full p-1">
                Showing Preview - Go Back to Editor
              </span>
            </button>
          </div>
        )}
      </div>
      {!showCompiled && !isPK ? (
        <MonacoEditor
          className="my-2"
          language={type || "javascript"}
          theme={isDark ? "vs-dark" : "vs-light"}
          value={body}
          options={{ wordWrap: "on", readOnly: !isEditing }}
          onChange={(body) => {
            if (!isLoading) {
              setBody(typeof body !== "undefined" ? body : "");
            }
          }}
        />
      ) : (
        <PartyKitEditor
          docId={data.id}
          isEditing={isEditing}
          type={data.type}
          ref={editorRef}
        />
      )}
      {showCompiled && (
        <MonacoEditor
          className="my-2"
          language={type || "javascript"}
          theme={isDark ? "vs-dark" : "vs-light"}
          value={compiledData?.compiled || ""}
          options={{ wordWrap: "on", readOnly: true, renderFinalNewline: "on" }}
        />
      )}
    </div>
  );
};

export default Editor;
