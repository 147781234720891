import { FC } from "react";
import Home from "./Home2";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Authenticator from "./Authenticator";
import Login from "./Login";
import Signup from "./Signup";
import AlertProvider from "./Alert";
import SharedHome from "./SharedHome";
import ModalPicker from "./ModalPicker";
import LoginForm from "./LoginForm";
import DarkModeProvider, { useDarkMode } from "./DarkModeProvider";

const search = window.location.search;
const q = new URLSearchParams(search);
const pk = q.get("pk");
if (pk !== null) {
  localStorage.setItem("isPK", pk as string);
  window.location.replace(window.location.pathname);
}

const TC = () => {
  const isDark = useDarkMode() 
  return (
    <ToastContainer
    // bodyStyle={{backgroundColor: isDark ? "black" : "white"}}
    theme={isDark ? "dark" : "light"}
    // className="text-black dark:text-white bg-white dark:bg-black"
    // toastClassName="text-black dark:text-white "
    // bodyClassName="text-black dark:text-white"
    hideProgressBar
    position="bottom-right"
    limit={2}
  />
  )

}

const App: FC = () => {
  return (
    <DarkModeProvider>
      <BrowserRouter>
        <AlertProvider>
          <ModalPicker>
            <Authenticator
              fallback={
                <Routes>
                  <Route path="/signup" element={<Signup />} />
                  <Route path="/login-form" element={<LoginForm />} />
                  <Route path="*" element={<Login />} />
                  <Route path="/shared/:guid" element={<SharedHome />} />
                  <Route path="/embed/:guid" element={<SharedHome />} />
                </Routes>
              }
            >
              <Home />
            </Authenticator>
          </ModalPicker>
        </AlertProvider>
           <TC/>
       
      </BrowserRouter>
    </DarkModeProvider>
  );
};

export default App;
