import {
  useMemo,
  createContext,
  useContext,
  useCallback,
  useEffect,
  useRef,
} from "react";
import { useAuthenticatedFetch } from "./Authenticator";
const BASEURL = "https://api.statechange.ai/api:scripttagme/";
export type EmbedData = {
  id: number;
  body: string;
  type: string;
  guid: string;
  name: string;
  is_shared: boolean;
  description: string;
};
const context = createContext({
  body: "",

  error: undefined,
  isLoading: false,

  refetch: () => {},
  save: async () => {},
  saving: false,
  type: "javascript",
  update: (newBody) => {
    console.log("Updating with body", newBody);
  },
  setBody: (newBody: string) => {
    console.log("Setting body to", newBody);
  },
  setType: (newType: string) => {
    console.log("Setting type to", newType);
  },
  data: undefined,
  unsaved: false,
  name: "",
  setName: (newName: string) => {
    console.log("Setting name to", newName);
  },
  isShared: false,
  setIsShared: (newIsShared: boolean) => {
    console.log("Setting isShared to", newIsShared);
  },
} as {
  body: string;

  error: Error | undefined;
  isLoading: boolean;

  refetch: () => void;
  save: () => Promise<void>;
  saving: boolean;
  type: string;
  update: (newData: EmbedData) => void;
  setBody: (newBody: string) => void;
  setType: (newType: string) => void;
  data?: EmbedData;
  unsaved: boolean;
  name: string;
  setName: (newName: string) => void;
  isShared: boolean;
  setIsShared: (newIsShared: boolean) => void;
  loaded: boolean;
  setLoaded: (newLoaded: boolean) => void;
  description: string;
  setDescription: (newName: string) => void;
});
const { Provider } = context;

export { context, Provider };

export const useEmbed = () => {
  return useContext(context);
};

export const useBase64Uri = () => {
  const { data } = useEmbed();
  return useCallback(() => {
    if (!data) return "";
    return `data:text/${data.type};base64,${window.btoa(data.body)}`;
  }, [data]);
};

export const useEmbedUrls = () => {
  const { type, data, name } = useEmbed();
  const filteredName = name.replace(/\s/g, "-").replace(/[^a-zA-Z0-9-_]/g, "");
  const devEmbedUrl = useMemo(() => {
    switch (type) {
      case "css":
        return `<link rel="stylesheet" href="${BASEURL}dev/${filteredName}_${data?.guid}">`;
      case "javascript":
        if (checkModule(data?.body || ""))
          return `<script type="module" src="${BASEURL}dev/${filteredName}_${data?.guid}"></script>`;
        else
          return `<script src="${BASEURL}dev/${filteredName}_${data?.guid}"></script>`;
      case "html":
        return `<iframe src="${BASEURL}dev/${filteredName}_${data?.guid}"></iframe>`;
    }
    return "";
  }, [data, type, filteredName]);
  const prodEmbedUrl = useMemo(() => {
    if (!data) return "";
    switch (data.type) {
      case "css":
        return `<link rel="stylesheet" href="${BASEURL}prod/${data?.guid}">`;
      case "javascript":
        if (checkModule(data?.body || ""))
          return `<script type="module" src="${BASEURL}prod/${data?.guid}"></script>`;
        else return `<script src="${BASEURL}prod/${data?.guid}"></script>`;
      case "html":
        return `<iframe src="${BASEURL}prod/${data?.guid}"></iframe>`;
    }
    return "";
  }, [data]);
  const bareDevEmbedUrl = useMemo(() => {
    switch (type) {
      case "css":
        return `${BASEURL}dev/${filteredName}_${data?.guid}`;
      case "javascript":
        return `${BASEURL}dev/${filteredName}_${data?.guid}`;
      case "html":
        return `${BASEURL}dev/${filteredName}_${data?.guid}`;
    }
    return "";
  }, [data, type, filteredName]);
  const bareProdEmbedUrl = useMemo(() => {
    if (!data) return "";
    switch (data.type) {
      case "css":
        return `${BASEURL}prod/${data?.guid}`;
      case "javascript":
        return `${BASEURL}prod/${data?.guid}`;
      case "html":
        return `${BASEURL}prod/${data?.guid}`;
    }
    return "";
  }, [data]);
  return { devEmbedUrl, prodEmbedUrl, bareDevEmbedUrl, bareProdEmbedUrl };
};

export const usePublish = () => {
  const { data } = useEmbed();
  const fetch = useAuthenticatedFetch();
  return useCallback(
    async (id: number, returnBare: boolean = false) => {
      if (!data) return "";
      const response = await fetch(`/me/publish/${id}`, {
        method: "POST",
      });
      const result = (await response.json()) as { url: string };
      if (returnBare) return result.url;
      switch (data.type) {
        case "css":
          return `<link rel="stylesheet" href="${result.url}">`;
        case "javascript":
          if (checkModule(data?.body || "")) {
            return `<script type="module" src="${result.url}"></script>`;
          } else {
            return `<script src="${result.url}"></script>`;
          }
        case "html":
          return `<iframe src="${result.url}"></iframe>`;
      }
      return "";
    },
    [fetch, data]
  );
};

function checkModule(body: string) {
  let isModule = false;
  //detect whether this is a module script
  const lines = body.split("\n");
  let isComment = false;
  if (lines)
    for (let line of lines) {
      if (line.trim().startsWith("// ")) continue;
      if (line.trim().startsWith("/*")) {
        isComment = true;
        continue;
      } else if (line.trim().endsWith("*/")) {
        isComment = false;
        continue;
      } else if (!isComment && line.trim().startsWith("import "))
        isModule = true;
      else break;
    }
  if (body.trim().includes("import ")) isModule = true;
  // console.log("Module is ", isModule);
  return isModule;
}

export const useWewebUrls = () => {
  const { type, data, name } = useEmbed();
  const isModule = checkModule(data?.body || "");
  const filteredName = name.replace(/\s/g, "-").replace(/[^a-zA-Z0-9-_]/g, "");
  const devWewebSnippet = useMemo(() => {
    switch (type) {
      case "css":
        return `if(!wwLib.getFrontWindow()["__scripttagme_${data?.guid}"]) {
          const link = document.createElement('link');
link.setAttribute('href', "${BASEURL}dev/${filteredName}_${data?.guid}");
link.setAttribute('rel', 'stylesheet');
document.head.appendChild(link);
wwLib.getFrontWindow()["__scripttagme_${data?.guid}"] = true;
}
`;
      case "javascript":
        if (isModule)
          return `if(!wwLib.getFrontWindow()["__scripttagme_${data?.guid}"]) {
          const script = document.createElement('script');
          script.setAttribute("type", "module")
          script.setAttribute('src', "${BASEURL}dev/${filteredName}_${data?.guid}");
          document.body.appendChild(script);
          wwLib.getFrontWindow()["__scripttagme_${data?.guid}"] = true;
                  }`;
        else
          return `if(!wwLib.getFrontWindow()["__scripttagme_${data?.guid}"]) {
          const script = document.createElement('script');
          script.setAttribute('src', "${BASEURL}dev/${filteredName}_${data?.guid}");
          document.body.appendChild(script);
          wwLib.getFrontWindow()["__scripttagme_${data?.guid}"] = true;
                  }`;
    }
    return "";
  }, [data, type, filteredName, isModule]);
  const prodWewebSnippet = useMemo(() => {
    if (!data) return "";
    switch (data.type) {
      case "css":
        return `if(!wwLib.getFrontWindow()["__scripttagme_${data?.guid}"]) {
          const link = document.createElement('link');
          link.setAttribute('href', "${BASEURL}prod/${data?.guid}");
          link.setAttribute('rel', 'stylesheet');
          document.head.appendChild(link);
          wwLib.getFrontWindow()["__scripttagme_${data?.guid}"] = true;
                  }
          `;
      case "javascript":
        if (isModule)
          return `if(!wwLib.getFrontWindow()["__scripttagme_${data?.guid}"]) {
        const script = document.createElement('script');
        script.setAttribute("type", "module")
        script.setAttribute('src', "${BASEURL}prod/${data?.guid}");
        document.body.appendChild(script);
        wwLib.getFrontWindow()["__scripttagme_${data?.guid}"] = true;
                }`;
        else
          return `if(!wwLib.getFrontWindow()["__scripttagme_${data?.guid}"]) {
          const script = document.createElement('script');
          script.setAttribute('src', "${BASEURL}prod/${data?.guid}");
          document.body.appendChild(script);
          wwLib.getFrontWindow()["__scripttagme_${data?.guid}"] = true;
                  }`;
    }

    return "";
  }, [data, isModule]);
  const waitScript = `
const wait = async () => {
  while (!wwLib.getFrontWindow()["__scripttagme_${data?.guid}"]) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }
};
}
  `;
  return { devWewebSnippet, prodWewebSnippet, isModule, waitScript };
};

export const useShare = () => {
  const { data, save, setIsShared, isShared } = useEmbed();
  const share = useCallback(async () => {
    if (!data) return;
    if (!isShared) {
      setIsShared(true);
    }
  }, [data, setIsShared, isShared]);
  const saveRef = useRef(save);
  saveRef.current = save;
  useEffect(() => {
    saveRef.current();
  }, [isShared]);
  return share;
};
export const useShareLink = () => {
  const { data, isShared } = useEmbed();
  const shareLink = useMemo(() => {
    if (!data || !isShared) return;
    return (
      window.location.protocol +
      "//" +
      window.location.host +
      "/shared/" +
      data.guid
    );
  }, [data, isShared]);
  return shareLink;
};
