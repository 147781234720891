import { BoltIcon } from "@heroicons/react/24/outline";
import clipboardCopy from "clipboard-copy";
import { FC, Fragment } from "react";
import { toast } from "react-toastify";
import { useWewebUrls } from "./EmbedProviderBase";
import { useAlert } from "./Alert";
import { SidebarItem, SidebarLabel } from "./catalyst/sidebar";
const WewebEmbed: FC = () => {
  const { alert } = useAlert();
  const { devWewebSnippet } = useWewebUrls();
  // const { devWewebSnippet, prodWewebSnippet } = useWewebUrls();
  if (!devWewebSnippet) return null;
  return (
    <SidebarItem
      // className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
      onClick={async () => {
        clipboardCopy(devWewebSnippet);
        toast.success("Copied development snippet to clipboard");
        alert({
          title: "Please paste this into your Weweb site",
          message: (
            <Fragment>
              <p>To add to your weweb site</p>
              <ol className="list-decimal pl-5">
                <li>Open Your Page</li>
                <li>Select or create a page-load workflow</li>
                <li>Add a custom javascript action</li>
                <li>Click where it says javascript</li>
                <li>Paste (ctrl-v or cmd-v)</li>
                <li>Done!</li>
              </ol>
            </Fragment>
          ),
        });
      }}
    >
      <BoltIcon
        className=" h-6 w-6"
        aria-hidden="true"
      />
      <SidebarLabel>Copy Weweb Snippet</SidebarLabel>
      
    </SidebarItem>
  );
};
export default WewebEmbed;
