import { ReactNode, createContext, useContext } from "react";
export const context = createContext({
  title: "",
  setTitle: (() => {}) as (newTitle: ReactNode) => void,
  pageKey: "",
  setPageKey: (() => {}) as (newPageKey: string) => void,
} as { title: ReactNode; setTitle: (newTitle: ReactNode) => void; pageKey: string; setPageKey: (newPageKey: string) => void });
export const Provider = context.Provider;
export const useHome = () => {
  return useContext(context);
};
