import Editor from "./Editor";
import { useNavigate, useParams } from "react-router-dom";
import EmbedProvider from "./EditEmbedProvider";
import { useHome } from "./HomeBase";
import { useEffect } from "react";
import { useAuthenticatedFetch } from "./Authenticator";

const EditEmbed = () => {
  const { id } = useParams();
  const { setPageKey } = useHome();
  useEffect(() => {
    setPageKey("editembed");
  }, [setPageKey]);
  const navigate = useNavigate();
  const fetch = useAuthenticatedFetch();
  if (!id) return null;
  if (isNaN(parseInt(id))) navigate("/");
  useEffect(() => {
    if(id && id.includes("_")) {
      const guid = id.split("_").pop();
      (async ()=>{
        const response = await fetch(`/embed/${guid}`)
        if(response.ok) {
          const {id } = await response.json()
          if(id) {
            navigate(`/${id}`)
            return
          }
        }
        navigate("/shared/" + guid)
      })()
    }else  if(id && id.includes("-")) {
      const guid = id;
      (async ()=>{
        const response = await fetch(`/embed/${guid}`)
        if(response.ok) {
          const {id } = await response.json()
          if(id) {
            navigate(`/${id}`)
            return
          }
        }
        navigate("/shared/" + guid)
      })()
    }
  }, [id, fetch])
  if(parseInt(id)) {
  return (
    <EmbedProvider id={parseInt(id)}>
      <Editor />
    </EmbedProvider>
  );
} else {
  return <div>Loading...</div>
}
};

export default EditEmbed;
