import {
  createContext,
  FC,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dialog, DialogTitle } from "./catalyst/dialog";
import { Subheading } from "./catalyst/heading";
import { Text } from "./catalyst/text";
import { Button } from "./catalyst/button";
export type MPOption = {
  title: string;
  subtitle?: string;
  key: string;
  icon?: ReactElement;
};
const context = createContext({
  show: false,
  setShow: (show: boolean) => {
    console.log("setShow", show);
  },
  title: "Are you sure?",
  setTitle: (message: string) => {
    console.log("setTitle", message);
  },
  message: "Are you sure you want to do this?",
  setMessage: (message: string) => {
    console.log("setMessage", message);
  },
  options: [] as MPOption[],
  setOptions: (options: MPOption[]) => {
    console.log("setOptions", options);
  },
  actionLabel: "Confirm" as string | undefined,
  setActionLabel: (label?: string) => {
    console.log("setActionLabel", label);
  },
  cancelLabel: "Cancel" as string | undefined,
  setCancelLabel: (label?: string) => {
    console.log("setCancelLabel", label);
  },
  setAction: (action: string) => {
    console.log("setAction", action);
  },
  action: "cancel",
  onFinalize: (state: string) => {
    console.log("onFinalize", state);
  },
  setOnFinalize: (finalize: (state: string) => void) => {
    console.log("setOnFinalize", finalize);
  },
});

const { Provider } = context;

const ModalPicker: FC<{ children: ReactElement }> = ({ children }) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [actionLabel, setActionLabel] = useState<string | undefined>("Confirm");
  const [cancelLabel, setCancelLabel] = useState<string | undefined>("Cancel");
  const [action, setAction] = useState("cancel");
  const [onFinalize, setOnFinalize] = useState(() => (state: string) => {
    console.log("onFinalize", state);
  });
  const [options, setOptions] = useState<MPOption[]>([]);
  const value = useMemo(
    () => ({
      show,
      setShow,
      title,
      setTitle,
      message,
      setMessage,
      actionLabel,
      setActionLabel,
      cancelLabel,
      setCancelLabel,
      action,
      setAction,
      onFinalize,
      setOnFinalize,
      options,
      setOptions,
    }),
    [
      show,
      setShow,
      title,
      setTitle,
      message,
      setMessage,
      actionLabel,
      setActionLabel,
      cancelLabel,
      setCancelLabel,
      action,
      setAction,
      onFinalize,
      setOnFinalize,
      options,
      setOptions,
    ]
  );

  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  return (
    <Provider value={value}>
      {children}

      <Dialog
        // as="div"
        className="relative z-10"
        // initialFocus={cancelButtonRef}
        onClose={setShow}
        open={show}
      >
        {/* <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
             
                <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6"> */}
        <div className="sm:flex sm:items-start">
          {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div> */}
          {/* <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10"> */}
          {/* <ListBulletIcon
            className="size-6"
            // className="h-6 w-6 text-blue-600"
            aria-hidden="true"
          /> */}
          {/* </div> */}
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
            <DialogTitle
            // as="h3"
            // className="text-lg font-medium leading-6 text-gray-900"
            >
              {title}
            </DialogTitle>
            <div className="mt-2 w-full">
              {!!message && <p className="text-sm text-gray-500">{message}</p>}
              <div className="w-full mx-2 mr-4">
                {options.map((option, index) => (
                  <button
                    key={option.key}
                    className="p-2 w-full rounded-md sc-container-header sc-container-border-color border-2 hover-shadow-md hover:opacity-50 animate-all duration-150 flex items-start mt-2 flex-col group animated"
                    onClick={() => {
                      setAction(option.key);
                      onFinalize(option.key);
                    }}
                    autoFocus={index === 0}
                  >
                    <div className="flex flex-row gap-x-2">
                      <div className="size-6">{option.icon}</div>
                      <div className="flex flex-col">
                        <Subheading className="text-left">
                          {option.title}
                        </Subheading>
                        <Text className="text-left">{option.subtitle}</Text>
                      </div>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          {actionLabel && (
            <Button
              type="button"
              color="red"
              // className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={() => {
                setAction("accept");
                onFinalize("accept");
              }}
            >
              {actionLabel}
            </Button>
          )}
          {cancelLabel && (
            <Button
              type="button"
              // className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
              onClick={() => {
                setAction("cancel");
                onFinalize("cancel");
              }}
              ref={cancelButtonRef}
            >
              {cancelLabel}
            </Button>
          )}
        </div>
        {/* </Dialog.Panel>
              </Transition.Child>
            </div>
          </div> */}
      </Dialog>
      {/* </Transition.Root> */}
    </Provider>
  );
};
export default ModalPicker;

export const useModalPicker = () => {
  const {
    setTitle,
    setMessage,
    setOnFinalize,
    setShow,
    show,
    setActionLabel,
    setCancelLabel,
    setOptions,
  } = useContext(context);

  const alert = useCallback(
    async (options: {
      title: string;
      message?: string;
      actionLabel?: string;
      cancelLabel?: string;
      options: MPOption[];
    }) => {
      const {
        title,
        message,
        actionLabel,
        cancelLabel,
        options: myOptions,
      } = options;
      if (show) throw new Error("Cannot show an alert when other is displayed");
      setTitle(title);
      setMessage(message || "");
      setShow(true);
      setCancelLabel(cancelLabel);
      setActionLabel(actionLabel);
      setOptions(myOptions);
      const p = new Promise<string>((resolve) => {
        setOnFinalize(() => resolve);
      });
      const result = await p;
      setShow(false);
      return result;
    },
    [
      setMessage,
      setOnFinalize,
      setTitle,
      show,
      setShow,
      setActionLabel,
      setCancelLabel,
      setOptions,
    ]
  );

  return alert;
};
