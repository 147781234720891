import { CodeBracketIcon } from "@heroicons/react/24/outline";
import clipboardCopy from "clipboard-copy";
import { FC } from "react";
import { toast } from "react-toastify";
import { useModalPicker } from "./ModalPicker";
import { useEmbed, useEmbedUrls, usePublish } from "./EmbedProviderBase";
import { SidebarItem, SidebarLabel } from "./catalyst/sidebar";
const EmbedTag: FC = () => {
  const pick = useModalPicker();
  const { type, data } = useEmbed();
  const { id } = data || { id: 0 };
  const { devEmbedUrl } = useEmbedUrls();
  const publish = usePublish();
  return (
    <SidebarItem
      // className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
      onClick={async () => {
        const result = await pick({
          title: "What kind of tag do you want?",
          message:
            "Click one of the options below to select a fast-responding development version, or a scalable production tag.",
          options: [
            {
              title: "Development Version",
              subtitle:
                "Use this when you are still working on your site. Up to date, but slower to load at scale",
              key: "dev",
            },
            {
              title: "Production Version",
              subtitle:
                "Use this when you're getting more traffic on your site. Faster to respond, but uses more caching",
              key: "prod",
            },
          ],
          cancelLabel: "Cancel",
        });
        if (result === "dev") {
          clipboardCopy(devEmbedUrl);
          toast.success("Copied development tag to clipboard");
        } else if (result === "prod") {
          const url = await publish(id);
          clipboardCopy(url);
          toast.success("Copied production tag to clipboard");
        }
      }}
    >
      <CodeBracketIcon
        className=" h-6 w-6 "
        aria-hidden="true"
      />
      <SidebarLabel>
        Copy{" "}
        {type === "javascript"
          ? "<script> tag"
          : type === "css"
          ? "<link> tag"
          : "<iframe> tag"}
      </SidebarLabel>

    </SidebarItem>
  );
};
export default EmbedTag;
