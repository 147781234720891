import { Fragment, ReactNode, useMemo, useState, FC, useCallback } from "react";
import {
  ArrowRightIcon,
  ArrowTopRightOnSquareIcon,
  ChevronUpIcon,
  ComputerDesktopIcon,
  ExclamationCircleIcon,
  MoonIcon,
  SunIcon,
} from "@heroicons/react/24/outline";
import { useAuthentication, useMe } from "./Authenticator";
import Logo from "./Logo";
import MainRoutes from "./MainRoutes";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Provider } from "./HomeBase";
import { useAlert } from "./Alert";
import { SidebarLayout } from "./catalyst/sidebar-layout";
import {
  Navbar,
  NavbarItem,
  NavbarSection,
  NavbarSpacer,
} from "./catalyst/navbar";
import {
  Sidebar,
  SidebarBody,
  SidebarFooter,
  SidebarHeader,
  SidebarItem,
  SidebarSection,
} from "./catalyst/sidebar";
import { Avatar } from "./catalyst/avatar";
import {
  Dropdown,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
  DropdownLabel,
  DropdownDivider,
} from "./catalyst/dropdown";
import { Heading } from "./catalyst/heading";
// import { useNavigate } from "react-router-dom";
import { useDarkMode } from "./DarkModeProvider";
import { useModalPicker } from "./ModalPicker";
import { Divider } from "./catalyst/divider";
const userNavigation = [
  //   { name: "Your Profile", href: "#" },
  //   { name: "Settings", href: "#" },
  {
    name: "Open Statechange",
    href: "https://pro.statechange.ai",
    icon: ArrowTopRightOnSquareIcon,
  },
  {
    name: "Report a Problem",
    href: "https://forms.reform.app/jpVWm8/xxt-feedback/KW3kXP",
    icon: ExclamationCircleIcon,
  },
  //   { name: "Open Xano", href: "https://app.xano.com", icon: XMarkIcon },
  // { name: "Sign out", href: "#", clickHandler: "signout" },
];

function AccountDropdownMenu({
  anchor,
}: {
  anchor: "top start" | "bottom end";
}) {
  const { logout } = useAuthentication();
  const { confirm } = useAlert();
  const { setTheme } = useDarkMode();
  const picker = useModalPicker();
  return (
    <DropdownMenu className="min-w-64" anchor={anchor}>
      {/* <DropdownItem href="#">
        <UserCircleIcon />
        <DropdownLabel>My account</DropdownLabel>
      </DropdownItem>
      <DropdownDivider />
      <DropdownItem href="#">
        <ShieldCheckIcon />
        <DropdownLabel>Privacy policy</DropdownLabel>
      </DropdownItem>
      <DropdownItem href="#">
        <LightBulbIcon />
        <DropdownLabel>Share feedback</DropdownLabel>
      </DropdownItem>
      */}
      {userNavigation.map(({ name, href, ...rest }) =>
        name ? (
          <DropdownItem key={name} href={href} target="_blank">
            <rest.icon />
            {name}
          </DropdownItem>
        ) : (
          <DropdownDivider />
        )
      )}
      <DropdownItem
        onClick={async () => {
          console.log("clicked");
          const result = await picker({
            title: "Select Theme",
            options: [
              {
                title:
                  "Light" +
                  (localStorage.theme === "light" ? " (current)" : ""),
                key: "light",
                icon: <SunIcon />,
              },
              {
                title:
                  "Dark" + (localStorage.theme === "dark" ? " (current)" : ""),
                key: "dark",
                icon: <MoonIcon />,
              },
              {
                title:
                  "System" +
                  (localStorage.theme === "system" ? " (current)" : ""),
                key: "system",
                icon: <ComputerDesktopIcon />,
              },
            ],
          });
          console.log("result", result);
          if (result && result !== "cancel") {
            localStorage.theme = result;
            setTheme(localStorage.theme);
          }
          // setTheme(theme === "dark" ? "light" : "dark");
        }}
      >
        <SunIcon />
        <DropdownLabel>Toggle Light/Dark Mode</DropdownLabel>
      </DropdownItem>
      <DropdownDivider />
      <DropdownItem
        onClick={async () => {
          const isok = await confirm({
            title: "Exit State Change?",
            message: "Lets get out of here",
          });
          if (isok) logout();
        }}
      >
        <ArrowRightIcon />
        <DropdownLabel>Sign out</DropdownLabel>
      </DropdownItem>
    </DropdownMenu>
  );
}

const NewHome: FC = () => {
  const [setSidebar] = useState<ReactNode>(null);
  // const sidebarRef = useRef<ReactNode>(null);
  // const setSidebar = (sidebar: ReactNode) => {
  //   sidebarRef.current = sidebar;
  // };
  // const navigate = useNavigate();
  const { data: userData } = useMe();
  // return "Hello World";

  const [title, setTitle] = useState<ReactNode>("");
  const [showTitle, setShowTitle] = useState(true);
  const [pageKey, setPageKey] = useState("");
  const setPageTitle = useCallback(
    (title: ReactNode, doShow: boolean = true) => {
      setTitle(title);
      setShowTitle(doShow);
    },
    []
  );
  const value = useMemo(
    () => ({
      title,
      setTitle: setPageTitle,
      pageKey,
      setPageKey,
      setSidebar,
    }),
    [title, pageKey, setSidebar, setPageTitle]
  );

  return (
    <Provider value={value}>
      <Helmet>
        <title>{title} | ScriptTag by State Change</title>
      </Helmet>
      <SidebarLayout
        navbar={
          <Navbar>
            <NavbarSpacer />
            <NavbarSection>
              <Dropdown>
                <DropdownButton as={NavbarItem}>
                  <Avatar
                    src={userData?.avatar_url}
                    initials={userData?.name
                      .split(/\s/)
                      .map((n) => n[0])
                      .join("")}
                    square
                  />
                </DropdownButton>
                <AccountDropdownMenu anchor="bottom end" />
              </Dropdown>
            </NavbarSection>
          </Navbar>
        }
        sidebar={
          // null
          <Sidebar>
            <SidebarHeader>
              {/* <Dropdown>
                <DropdownButton as={SidebarItem}>
                  {({ active }) => ( */}
              <Fragment>
                <Link to="/">
                  <div className="flex flex-row gap-x-2">
                    <Logo className="h-8 w-8 mt-2" />
                    <div className="flex-col flex">
                      <div className="text-lg font-extrabold">ScriptTag</div>
                      <div className="text-xs font-semibold -mt-1">
                        by State Change
                      </div>
                    </div>
                  </div>
                </Link>
                {/* <ChevronRightIcon
                        className={clsx(
                          "size-3 transition-transform duration-150",
                          active ? "rotate-90" : "rotate-0",
                        )}
                      /> */}
              </Fragment>
              {/* )} */}
              {/* </DropdownButton>
                <DropdownMenu className="min-w-80 " anchor="bottom start">
                  {navigation.map(({ name, href, ...rest }) =>
                    name ? (
                      <DropdownItem key={name} href={href} target="_blank">
                        {rest.icon && <rest.icon className="size-4" />}
                        {name}
                      </DropdownItem>
                    ) : (
                      <DropdownDivider />
                    ),
                  )} */}

              {/* {userNavigation.map(({ name, href }) =>
                    name ? (
                      <DropdownItem key={name} href={href} target="_blank">

                        {name}
                      </DropdownItem>
                    ) : (
                      <DropdownDivider />
                    ),
                  )} */}
              {/* <DropdownItem href="/settings">
                    <Cog8ToothIcon />
                    <DropdownLabel>Settings</DropdownLabel>
                  </DropdownItem>
                  <DropdownDivider />
                  <DropdownItem href="#">
                    <Avatar slot="icon" src="/teams/catalyst.svg" />
                    <DropdownLabel>Catalyst</DropdownLabel>
                  </DropdownItem>
                  <DropdownItem href="#">
                    <Avatar
                      slot="icon"
                      initials="BE"
                      className="bg-purple-500 text-white"
                    />
                    <DropdownLabel>Big Events</DropdownLabel>
                  </DropdownItem>
                  <DropdownDivider />
                  <DropdownItem href="#">
                    <PlusIcon />
                    <DropdownLabel>New team&hellip;</DropdownLabel>
                  </DropdownItem> */}
              {/* </DropdownMenu>
              </Dropdown> */}
            </SidebarHeader>
            <SidebarBody>
              {/* <SidebarItem
                onClick={() => {
                  navigate("/");
                }}
              >
                <HomeIcon />
                <SidebarLabel>My Folders</SidebarLabel>
              </SidebarItem> */}
              {/* <SidebarItem
                onClick={() => navigate("/")}
                current={pageKey === "start"}
              >
                <HomeIcon />
                <SidebarLabel>Home</SidebarLabel>
              </SidebarItem>
              <SidebarItem
                onClick={() => navigate("/search")}
                current={pageKey === "search"}
              >
                <MagnifyingGlassIcon />
                <SidebarLabel>Search</SidebarLabel>
              </SidebarItem>
              <SidebarItem
                onClick={() => navigate("/tags")}
                current={pageKey === "tags"}
              >
                <TagIcon />
                <SidebarLabel>Tags</SidebarLabel>
              </SidebarItem>
              <SidebarItem
                onClick={() => navigate("/favorites")}
                current={pageKey === "favorites"}
              >
                <StarIcon />
                <SidebarLabel>Favorites</SidebarLabel>
              </SidebarItem>{" "}
              <SidebarItem
                onClick={() => navigate("/viewed")}
                current={pageKey === "viewed"}
              >
                <EyeIcon />
                <SidebarLabel>Viewed</SidebarLabel>
              </SidebarItem> */}
              {/* <SidebarItem
                href="https://chromewebstore.google.com/detail/statechange-low-code-powe/jgednopabapolfhfbgipkkigkafnlmla"
                target="_blank"
              >
                <PuzzlePieceIcon />
                <SidebarLabel>
                  Download Extension{" "}
                  <ArrowTopRightOnSquareIcon className="sc-text-secondary size-3 inline -mt-1" />
                </SidebarLabel>
              </SidebarItem> */}
              {/* <SidebarDivider /> */}
              {/* <SidebarSection>
                {navigation.map((item) => (
                  <SidebarItem
                    key={item.name}
                    href={item.href}
                    current={item.current}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? "text-gray-300"
                          : "text-gray-400 group-hover:text-gray-300",
                        "h-6 w-6 flex-shrink-0",
                      )}
                      aria-hidden="true"
                    />
                    <SidebarLabel>{item.name}</SidebarLabel>
                  </SidebarItem>
                ))}
              </SidebarSection> */}
              {/* <SidebarSection>{sidebarRef.current}</SidebarSection> */}
              <SidebarSection className="sidebar-portal" />
              {/* <SidebarSection className="max-lg:hidden">
              <SidebarHeading>Upcoming Events</SidebarHeading>
              {events.map((event) => (
                <SidebarItem key={event.id} href={event.url}>
                  {event.name}
                </SidebarItem>
              ))}
            </SidebarSection> */}
              {/* <SidebarSpacer /> */}
              {/* <SidebarSection>
                <SidebarItem href="#">
                  <QuestionMarkCircleIcon />
                  <SidebarLabel>Support</SidebarLabel>
                </SidebarItem>
                <SidebarItem href="#">
                  <SparklesIcon />
                  <SidebarLabel>Changelog</SidebarLabel>
                </SidebarItem>
              </SidebarSection> */}
            </SidebarBody>
            <SidebarFooter className="max-lg:hidden">
              <Dropdown>
                <DropdownButton as={SidebarItem}>
                  <span className="flex min-w-0 items-center gap-3">
                    <Avatar
                      src={userData?.avatar_url}
                      initials={userData?.name
                        .split(/\s/)
                        .map((n) => n[0])
                        .join("")}
                      square
                      className="size-10"
                    />
                    <span className="min-w-0">
                      <span className="block truncate text-sm/5 font-medium text-zinc-950 dark:text-white">
                        {userData?.name}
                      </span>
                      <span className="block truncate text-xs/5 font-normal text-zinc-500 dark:text-zinc-400">
                        {userData?.email}
                      </span>
                    </span>
                  </span>
                  <ChevronUpIcon />
                </DropdownButton>
                <AccountDropdownMenu anchor="top start" />
              </Dropdown>
            </SidebarFooter>
          </Sidebar>
        }
      >
        {title && showTitle && (
          <div className="flex flex-1">
            {typeof title === "string" ? (
              <Fragment>
                <div className="flex flex-col w-full gap-y-2">
                  <Heading level={1}>{title}</Heading>
                  <Divider />
                </div>
              </Fragment>
            ) : (
              title
            )}
          </div>
        )}
        <MainRoutes />
      </SidebarLayout>
    </Provider>
  );
};

export default NewHome;
