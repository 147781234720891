import { FC, useCallback, useEffect, useMemo } from "react";
import { Field, Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import {
  useAuthenticatedFetch,
  useAuthenticatedQuery,
  useMe,
} from "./Authenticator";
import { SCMProject } from "./Projects";
import { EmbedData } from "./EmbedProviderBase";
import EmbedList from "./EmbedList";
import AddEmbed from "./AddEmbed";
import { useHome } from "./HomeBase";
import { toast } from "react-toastify";
import SidebarPortal from "./SidebarPortal";
import { useProjectRemove } from "./useProjectRemove";
import { TrashIcon } from "@heroicons/react/24/outline";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { CloudArrowDownIcon } from "@heroicons/react/20/solid";
import { useAlert } from "./Alert";
const RAY_ID = 4;
const Project: FC = () => {
  const { setTitle } = useHome();
  const { data: me } = useMe();
  const meId = useMemo(() => me?.id, [me?.id]);
  const { id } = useParams();
  const idNumber = parseInt(id || "0");
  const { data, refetch, error } = useAuthenticatedQuery<{
    project: SCMProject;
    embeds: EmbedData[];
  }>(`/me/project/${id}`, undefined, typeof id === "undefined");
  useEffect(() => {
    setTitle(data?.project?.name || "Project");
  }, [setTitle, data?.project?.name]);
  const navigate = useNavigate();
  useEffect(() => {
    if (error) {
      toast.error("Error loading project");
      navigate("/");
    }
  }, [error, navigate]);
  const fetch = useAuthenticatedFetch();
  const javascripts = data?.embeds.filter((e) => e.type === "javascript");
  const cssscripts = data?.embeds.filter((e) => e.type === "css");
  const htmlscripts = data?.embeds.filter((e) => e.type === "html");
  const remove = useProjectRemove();
  const unshare = useCallback(
    async (userId: number) => {
      const body = JSON.stringify({
        remove_user_id: userId,
        project_id: id,
      });
      await fetch("/me/share", { body, method: "POST" });
      refetch();
    },
    [refetch, fetch, id]
  );
  const share = useCallback(
    async (userId: number) => {
      const body = JSON.stringify({
        add_user_id: userId,
        project_id: id,
      });
      await fetch("/me/share", { body, method: "POST" });
      refetch();
    },
    [refetch, fetch, id]
  );
  const transfer = useCallback(
    async (userId: number) => {
      const body = JSON.stringify({
        user_id: userId,
        project_id: id,
      });
      await fetch("/me/transfer", { body, method: "POST" });
      refetch();
    },
    [refetch, fetch, id]
  );
  const { confirm } = useAlert();

  return (
    <div className="flex-col space-y-4">
      {data && (
        <SidebarPortal>
          <Formik
            initialValues={{
              projectName: data?.project.name,
              projectDescription: data?.project.description,
            }}
            onSubmit={async (values) => {
              const response = await fetch(`/me/project/${id}`, {
                method: "POST",
                body: JSON.stringify({
                  name: values.projectName,
                  description: values.projectDescription,
                }),
              });
              if (response.ok) {
                toast.success(values.projectName + " updated!");
                await refetch();
                //   form.resetForm();
              } else {
                toast.error("Failed to update project");
              }
            }}
          >
            {({ isSubmitting, dirty }) => (
              <Form>
                <div className="flex justify-between p-5 shadow-md rounded-md flex-col space-y-4">
                  <div>
                    <label
                      htmlFor="projectName"
                      className="block text-sm font-medium leading-6 text-gray-200"
                    >
                      Name
                    </label>
                    <div className="mt-2">
                      <Field
                        name="projectName"
                        id="projectName"
                        className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-100 shadow-sm ring-1 ring-inset ring-gray-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 bg-black"
                        placeholder="My Project"
                      />
                    </div>
                  </div>
                  <div>
                    <div className="flex justify-between">
                      <label
                        htmlFor="projectDescription"
                        className="block text-sm font-medium leading-6 text-gray-200"
                      >
                        Description
                      </label>
                      <span
                        className="text-xs leading-6 text-gray-500"
                        id="projectDescription-optional"
                      >
                        Optional
                      </span>
                    </div>
                    <div className="mt-2">
                      <Field
                        as="textarea"
                        name="projectDescription"
                        id="projectDescription"
                        className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-100 shadow-sm ring-1 ring-inset ring-gray-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 bg-black"
                        placeholder="Project Description"
                        aria-describedby="projectDescription-optional"
                      />
                    </div>
                  </div>
                  <button
                    disabled={isSubmitting || !dirty}
                    type="submit"
                    className={
                      isSubmitting || !dirty
                        ? "opacity-50 rounded-md bg-gray-600 py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm"
                        : "rounded-md bg-blue-600 py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    }
                  >
                    {isSubmitting ? "Saving" : "Save Changes"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="w-full mx-2">
            <button
              className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => {
                remove(idNumber, () => {
                  navigate("/");
                });
              }}
            >
              <TrashIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Delete
            </button>
            {(!!javascripts?.length ||
              !!cssscripts?.length ||
              htmlscripts?.length) && (
              <button
                className="w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
                onClick={() => {
                  const archive = new JSZip();
                  if (javascripts)
                    javascripts.forEach((js) => {
                      archive.file(js.name + ".js", js.body);
                    });
                  if (cssscripts)
                    cssscripts.forEach((css) => {
                      archive.file(css.name + ".css", css.body);
                    });
                  if (htmlscripts)
                    htmlscripts.forEach((css) => {
                      archive.file(css.name + ".html", css.body);
                    });
                  archive.generateAsync({ type: "blob" }).then((content) => {
                    saveAs(content, data?.project.name + ".zip");
                  });
                }}
              >
                <CloudArrowDownIcon
                  className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                  aria-hidden="true"
                />
                Download Archive
              </button>
            )}
          </div>
          <div className="my-4 mx-4">
            <div className="">
              {!data?.project?.shared_with ||
                (!data?.project?.shared_with.length && (
                  <h3 className="text-white text-md font-medium">
                    Not Currently Shared
                  </h3>
                ))}
              {data?.project?.shared_with &&
                data?.project?.shared_with.length > 0 && (
                  <div>
                    <h3 className="text-white text-md font-medium">
                      Owned by{" "}
                      {me?.id === data?.project?.user_id
                        ? "Me"
                        : data?.project?._user.email}
                    </h3>

                    {/* <h3 className="text-white text-md font-medium">
                      Shared With
                    </h3> */}
                  </div>
                )}
            </div>
            <div className="w-full p-2 border-gray-500 border-2 rounded-md">
              {data?.project?.shared_with &&
                data?.project?.shared_with.length > 0 && (
                  <div className="">
                    <h4 className="text-white text-md font-medium">
                      Shared With
                    </h4>
                    <ul className="mx-4">
                      {data?.project?.shared_with.map(({ id, email }) => (
                        <div
                          key={id}
                          className="mt-2 text-white bg-blue-800 p-2 rounded-md overflow-ellipsis"
                        >
                          <div
                            title={email}
                            className="text-sm font-medium overflow-hidden text-ellipsis"
                          >
                            {email} {id === meId && "(me)"}{" "}
                          </div>
                          {meId === data.project?.user_id && (
                            <div>
                              <button
                                onClick={() => unshare(id)}
                                className="text-xs bg-blue-600 hover:bg-blue-800 py-0 px-2 rounded-full"
                              >
                                Unshare
                              </button>
                              {meId !== id && (
                                <button
                                  onClick={async () => {
                                    const result = await confirm({
                                      title: "Transfer project ownership?",
                                      message:
                                        "This will transfer ownership of this project to the user you select. You will no longer be able to manage this project.",
                                    });
                                    // console.log(result);
                                    // return;
                                    if (result === "accept") transfer(id);
                                  }}
                                  className="text-xs bg-blue-600 hover:bg-blue-800 py-0 px-2 rounded-full ml-2"
                                >
                                  Transfer
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      ))}
                    </ul>
                  </div>
                )}
              <h4 className="text-white text-md font-medium mt-4">Share:</h4>
              {meId &&
                meId === data?.project?.user_id &&
                !data?.project?.shared_with?.some((s) => s.id === meId) && (
                  <button
                    onClick={() => share(meId)}
                    className="mt-4 mx-4 block text-sm bg-blue-600 hover:bg-blue-800 py-1 px-2 rounded-md text-white "
                  >
                    Share With Myself
                  </button>
                )}
              {meId &&
                !data?.project?.shared_with?.some((s) => s.id === RAY_ID) && (
                  <button
                    onClick={() => share(RAY_ID)}
                    className="mt-4 mx-4 text-sm bg-blue-600 hover:bg-blue-800 py-1 px-2 rounded-md text-white "
                  >
                    Share With Ray at Statechange
                  </button>
                )}
              {meId && meId === data?.project?.user_id && (
                <Formik
                  initialValues={{
                    email: "",
                  }}
                  onSubmit={async (values) => {
                    const response = await fetch(`/me/share`, {
                      method: "POST",
                      body: JSON.stringify({
                        invite_user_email: values.email,
                        project_id: id,
                      }),
                    });
                    if (response.ok) {
                      toast.success(data?.project.name + " updated!");
                      await refetch();
                      //   form.resetForm();
                    } else {
                      toast.error("Failed to update project");
                    }
                  }}
                >
                  {({ isSubmitting, dirty }) => (
                    <Form>
                      <div className="flex justify-between p-5 shadow-md rounded-md flex-col space-y-4">
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium leading-6 text-gray-200"
                          >
                            Share with a Statechange Member
                          </label>
                          <div className="mt-2">
                            <Field
                              name="email"
                              id="email"
                              className="p-2 block w-full rounded-md border-0 py-1.5 text-gray-100 shadow-sm ring-1 ring-inset ring-gray-800 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6 bg-black"
                              placeholder="someone@domain.com"
                            />
                          </div>
                        </div>

                        <button
                          disabled={isSubmitting || !dirty}
                          type="submit"
                          className={
                            isSubmitting || !dirty
                              ? "opacity-50 rounded-md bg-gray-600 py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm"
                              : "rounded-md bg-blue-600 py-2.5 px-3.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                          }
                        >
                          {isSubmitting ? "Inviting..." : "Invite to share"}
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </SidebarPortal>
      )}
      {!!javascripts && (
        <div>
          <h2 className="text-gray-200 text-xl font-semibold mb-2">
            Javascript Files
          </h2>
          {javascripts.length > 0 ? (
            <EmbedList items={javascripts} onRemove={refetch} />
          ) : (
            <div className="w-full bg-white dark:bg-gray-800 rounded-md p-4">
              <div className="w-full p-4 text-xl flex justify-center">
                <div className="text-gray-900 dark:text-gray-100">
                  No JavaScript files found
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {!!cssscripts && (
        <div>
          <h2 className="text-gray-200 text-xl font-semibold mb-2">
            CSS Files
          </h2>
          {cssscripts.length > 0 ? (
            <EmbedList items={cssscripts} onRemove={refetch} />
          ) : (
            <div className="w-full bg-white dark:bg-gray-800 rounded-md p-4">
              <div className="w-full p-4 text-xl flex justify-center">
                <div className="text-gray-900 dark:text-gray-100">
                  No CSS files found
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {!!htmlscripts && (
        <div>
          <h2 className="text-gray-200 text-xl font-semibold mb-2">
            HTML Files
          </h2>
          {htmlscripts.length > 0 ? (
            <EmbedList items={htmlscripts} onRemove={refetch} />
          ) : (
            <div className="w-full bg-white dark:bg-gray-800 rounded-md p-4">
              <div className="w-full p-4 text-xl flex justify-center">
                <div className="text-gray-900 dark:text-gray-100">
                  No HTML files found
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <AddEmbed projectId={idNumber} />
    </div>
  );
};
export default Project;
