import {
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo,
  useCallback,
} from "react";
const DarkModeContext = createContext({
  theme: "system",
  setTheme: (theme: string) => {
    console.log("setTheme", theme);
  },
});
const { Provider } = DarkModeContext;
const DarkModeProvider = ({ children }: { children: React.ReactNode }) => {
  const currentTheme = localStorage.getItem("theme") as
    | "dark"
    | "light"
    | "system";
  const [theme, setTheme] = useState<"dark" | "light">(
    !currentTheme || currentTheme === "system"
      ? window.matchMedia("(prefers-color-scheme: dark)").matches
        ? "dark"
        : "light"
      : currentTheme
  );
  const newSetTheme = useCallback(() => {
    const currentTheme = localStorage.getItem("theme") as
      | "dark"
      | "light"
      | "system";
    setTheme(
      !currentTheme || currentTheme === "system"
        ? window.matchMedia("(prefers-color-scheme: dark)").matches
          ? "dark"
          : "light"
        : currentTheme
    );
  }, []);
  const value = useMemo(
    () => ({ theme, setTheme: newSetTheme }),
    [theme, newSetTheme]
  );
  useEffect(() => {
    if (theme === "dark") {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [theme]);
  useEffect(() => {
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", (e) => {
        if (localStorage.theme === "system") {
          const newTheme = e.matches ? "dark" : "light";
          setTheme(newTheme);
        }
      });
  }, []);

  return <Provider value={value}>{children}</Provider>;
};
export const useDarkMode = () => {
  const context = useContext(DarkModeContext);
  if (!context) {
    throw new Error("useDarkMode must be used within a DarkModeProvider");
  }
  return context;
};

export const useIsDark = () => {
  const { theme } = useDarkMode();
  return theme === "dark";
};
export default DarkModeProvider;
