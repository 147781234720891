import {
  CloudArrowDownIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import {
  useAuthenticatedFetch,
  useAuthenticatedQuery,
  useMe,
} from "./Authenticator";
import { toast } from "react-toastify";
import {  useNavigate } from "react-router-dom";
import { FC, Fragment, useCallback, useEffect } from "react";
import { useHome } from "./HomeBase";
import { EmbedData } from "./EmbedProviderBase";
import EmbedList from "./EmbedList";
// import { useProjectRemove } from "./useProjectRemove";
import AddEmbed from "./AddEmbed";
import SidebarPortal from "./SidebarPortal";
import saveAs from "file-saver";
import JSZip from "jszip";
import { ProjectList } from "./ProjectItem";
import { SidebarItem } from "./catalyst/sidebar";
export type SCMProject = {
  id: number;
  name: string;
  description: string;
  embeds?: number;
  shared_with: {
    id: number;
    email: string;
  }[];
  user_id: number;
  _user: {
    id: number;
    email: string;
    name: string;
    avatar_url: string;
  };
};

const AddProject: FC<{ onAdd: () => void }> = ({ onAdd }) => {
  const fetch = useAuthenticatedFetch();
  const navigate = useNavigate();
  const add = useCallback(async () => {
    const response = await fetch("/me/project", {
      method: "POST",
      body: JSON.stringify({
        name: "New Project",
        description: "A new project",
      }),
    });
    if (response.ok) {
      toast.success("Added a project");
      const newProject = (await response.json()) as SCMProject;
      navigate(`/project/${newProject.id}`);
      onAdd();
    }
  }, [fetch, onAdd, navigate]);
  return (
    <button
      className=" w-full bg-blue-600 hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
      onClick={add}
    >
      <PlusIcon className="h-5 w-5 inline-block mr-2" />
      Create a new Project
    </button>
  );
};

const Projects: FC = () => {
  const { data: meData } = useMe();
  const userId = meData?.id;
  // const remove = useProjectRemove();
  const { setTitle, setPageKey } = useHome();
  useEffect(() => {
    setTitle("My Projects");
    setPageKey("projectlist");
  }, [setTitle, setPageKey]);

  const { data, refetch } = useAuthenticatedQuery<{
    items: SCMProject[];
  }>("/me/project");
  const { data: embedData, refetch: refetchEmbed } = useAuthenticatedQuery<{
    embeds: EmbedData[];
    project: SCMProject;
  }>(`/me/project/0`);
  const javascripts = embedData?.embeds.filter((e) => e.type === "javascript");
  const cssscripts = embedData?.embeds.filter((e) => e.type === "css");
  const htmlscripts = embedData?.embeds.filter((e) => e.type === "html");
  const sortByName = false;
  if (!data)
    return (
      <div className="rounded-md p-5 bg-white dark:bg-zinc-800 shadow-md">
        <div className="animate-pulse text-xl text-zinc-600 dark:text-zinc-400">Loading...</div>
      </div>
    );
  return (
    <div className="flex-col space-y-4 mb-6">
      <SidebarPortal>
        {/* <div className="w-full mx-2"> */}
          {((!!javascripts && !!javascripts.length) ||
            (!!cssscripts && !!cssscripts.length)) && (
            <SidebarItem
              // className="text-left w-full text-gray-300 hover:bg-gray-700 hover:text-white group flex items-center rounded-md px-2 py-2 text-sm font-medium"
              onClick={() => {
                const archive = new JSZip();
                if (javascripts)
                  javascripts.forEach((js) => {
                    archive.file(js.name + ".js", js.body);
                  });
                if (cssscripts)
                  cssscripts.forEach((css) => {
                    archive.file(css.name + ".css", css.body);
                  });
                if (htmlscripts)
                  htmlscripts.forEach((html) => {
                    archive.file(html.name + ".html", html.body);
                  });
                archive.generateAsync({ type: "blob" }).then((content) => {
                  saveAs(content, "Unassigned Scripts.zip");
                });
              }}
            >
              <CloudArrowDownIcon
                className="text-gray-400 group-hover:text-gray-300 mr-3 h-6 w-6 flex-shrink-0"
                aria-hidden="true"
              />
              Download Unassigned Scripts
            </SidebarItem>
          )}
        {/* </div> */}
      </SidebarPortal>
      <div>
        {/* <h2 className="text-gray-200 text-2xl font-semibold mb-2">
          My Projects
        </h2> */}
        {!!data.items?.length &&
          data.items.some((project) => project.user_id === userId) && (
            <div className="overflow-hidden w-full p-5">
              <ProjectList
                projects={data.items
                  .filter((project) => project.user_id === userId)
                  .sort((a, b) =>
                    sortByName ? a.name.localeCompare(b.name) : a.id - b.id
                  )}
              />
            </div>
          )}

        {!!data.items?.length &&
          data.items.some((project) => project.user_id !== userId) && (
            <Fragment>
              <h2 className="text-gray-200 text-2xl font-semibold mb-2 mt-5">
                Projects Shared with Me
              </h2>
              <div className="overflow-hidden  w-full p-5">
                <ProjectList
                  projects={data.items
                    .filter((project) => project.user_id !== userId)
                    .sort((a, b) =>
                      sortByName ? a.name.localeCompare(b.name) : a.id - b.id
                    )}
                />
              </div>
            </Fragment>
          )}
      </div>
      <AddProject onAdd={refetch} />
      {!!javascripts && !!javascripts.length && (
        <div className="flex-col mt-8">
          <h2 className="text-gray-200 text-2xl font-semibold mb-2 mt-8">
            Javascript files without a Project
          </h2>
          <EmbedList
            items={javascripts}
            onRemove={() => {
              refetch();
              refetchEmbed();
            }}
          />
        </div>
      )}
      {!!cssscripts && !!cssscripts.length && (
        <div className="flex-col mt-8">
          <h2 className="text-gray-200 text-2xl font-semibold mb-2 mt-8">
            CSS files without a Project
          </h2>
          <EmbedList
            items={cssscripts}
            onRemove={() => {
              refetch();
              refetchEmbed();
            }}
          />
        </div>
      )}
      {!!htmlscripts && !!htmlscripts.length && (
        <div className="flex-col mt-8">
          <h2 className="text-gray-200 text-2xl font-semibold mb-2 mt-8">
            HTML files without a Project
          </h2>
          <EmbedList
            items={htmlscripts}
            onRemove={() => {
              refetch();
              refetchEmbed();
            }}
          />
        </div>
      )}
      <AddEmbed projectId={0} onAdd={refetchEmbed} />
    </div>
  );
};
export default Projects;
