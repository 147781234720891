import { FC, useEffect } from "react";
import { useAuthentication } from "./Authenticator";
import { Formik, Form, Field } from "formik";
import Logo from "./Logo";
import { useNavigate } from "react-router-dom";
import { Card } from "./components/ui/card";
import { Button } from "./components/ui/button";
import { Input } from "./components/ui/input";
import { Label } from "./components/ui/label";

const LoginForm: FC = () => {
  const navigate = useNavigate();
  const { loginWithPassword, loginWithCircle } = useAuthentication();
  useEffect(() => {
    loginWithCircle().catch((e) =>
      console.log("Could not login with circle", e)
    );
  }, [loginWithCircle]);
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={async ({ email, password }) => {
        await loginWithPassword(email, password);
        navigate("/");
      }}
    >
      {({ submitForm, isValid, dirty, isSubmitting }) => (
        <Form>
          <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <Logo className="mx-auto h-12 w-12" />
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-zinc-900 dark:text-white">
                <span className="font-medium text-zinc-500 dark:text-zinc-400">Sign in to</span>{" "}
                ScriptTag
              </h2>
              <p className="font-semibold text-center text-zinc-500 dark:text-zinc-400">
                by{" "}
                <a
                  className="text-zinc-900 dark:text-white hover:underline"
                  href="https://statechange.ai"
                >
                  State Change
                </a>
              </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <Card className="py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div className="space-y-6">
                  <div>
                    <Label htmlFor="email">
                      Email address
                    </Label>
                    <div className="mt-1">
                      <Field
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        autoFocus
                        as={Input}
                      />
                    </div>
                  </div>

                  <div>
                    <Label htmlFor="password">
                      Password
                    </Label>
                    <div className="mt-1">
                      <Field
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        required
                        as={Input}
                      />
                    </div>
                  </div>

                  <div>
                    <Button
                      disabled={isSubmitting || !isValid || !dirty}
                      type="button"
                      onClick={submitForm}
                      variant="default"
                      className="w-full"
                    >
                      Sign in with Email and Password
                    </Button>
                  </div>
                </div>

                <div className="mt-6">
                  <div className="relative">
                    <div className="absolute inset-0 flex items-center">
                      <div className="w-full border-t border-zinc-200 dark:border-zinc-700" />
                    </div>
                  </div>
                  <div className="mt-8">
                    <Button
                      variant="outline"
                      className="w-full"
                      asChild
                    >
                      <a
                        href={
                          "https://pro.statechange.ai/c/ask-for-help/?source=" +
                          encodeURIComponent(window.location.href)
                        }
                      >
                        Sign In With State Change
                      </a>
                    </Button>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default LoginForm;
