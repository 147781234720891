import {
  createContext,
  FC,
  Fragment,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
// import { Dialog, Transition } from "@headlessui/react";
import { Dialog, DialogTitle } from "./catalyst/dialog";
import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import copy from "clipboard-copy";
import { toast } from "react-toastify";
// import { Transition, TransitionChild } from "@headlessui/react";
import { Button } from "./catalyst/button";
const context = createContext({
  show: false,
  setShow: (() => {}) as (show: boolean) => void,
  title: "Are you sure?" as string | ReactElement,
  setTitle: (() => {}) as (message: string | ReactElement) => void,
  message: "Are you sure you want to do this?" as string | ReactElement,
  setMessage: (() => {}) as (message: string | ReactElement) => void,
  actionLabel: "Confirm" as string | undefined,
  setActionLabel: (() => {}) as (label?: string) => void,
  cancelLabel: "Cancel" as string | undefined,
  setCancelLabel: (() => {}) as (label?: string) => void,
  setAction: (() => {}) as (action: string) => void,
  action: "cancel",
  onFinalize: (() => {}) as (state: string) => void,
  setOnFinalize: (() => {}) as (finalize: (state: string) => void) => void,
  setType: (() => {}) as (type: string) => void,
});

const { Provider } = context;

const AlertProvider: FC<{ children: ReactElement }> = ({ children }) => {
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState<string | ReactElement>("");
  const [message, setMessage] = useState<string | ReactElement>("");
  const [actionLabel, setActionLabel] = useState<string | undefined>("Confirm");
  const [cancelLabel, setCancelLabel] = useState<string | undefined>("Cancel");
  const [action, setAction] = useState("cancel");
  const [onFinalize, setOnFinalize] = useState(() => (state: string) => {
    console.log("I am in onFinalize", state);
  });
  const [type, setType] = useState("alert");
  const value = useMemo(
    () => ({
      show,
      setShow,
      title,
      setTitle,
      message,
      setMessage,
      actionLabel,
      setActionLabel,
      cancelLabel,
      setCancelLabel,
      action,
      setAction,
      onFinalize,
      setOnFinalize,
      setType,
    }),
    [
      show,
      setShow,
      title,
      setTitle,
      message,
      setMessage,
      actionLabel,
      setActionLabel,
      cancelLabel,
      setCancelLabel,
      action,
      setAction,
      onFinalize,
      setOnFinalize,
      setType,
    ],
  );
  const messageRef = useRef<HTMLDivElement>(null);
  const cancelButtonRef = useRef<HTMLButtonElement>(null);
  return (
    <Provider value={value}>
      {children}
      {/* <Transition show={show} as={Fragment}> */}
      <Dialog
        // as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setShow}
        open={show}
      >
        <Fragment>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              {type === "alert" && (
                <ExclamationTriangleIcon
                  className="h-6 w-6 text-red-600"
                  aria-hidden="true"
                />
              )}
              {type === "success" && (
                <CheckCircleIcon
                  className="h-6 w-6 text-green-600"
                  aria-hidden="true"
                />
              )}
              {type === "information" && (
                <InformationCircleIcon
                  className="h-6 w-6 text-blue-600"
                  aria-hidden="true"
                />
              )}
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <DialogTitle className="text-lg font-medium leading-6 text-gray-900">
                {title}
              </DialogTitle>
              <div
                className="mt-2 cursor-pointer"
                onClick={() => {
                  copy(
                    typeof message === "string"
                      ? message
                      : messageRef.current?.innerText || "",
                  );
                  toast.success("Copied alert text to clipboard");
                }}
              >
                <p className="text-sm text-gray-500" ref={messageRef}>
                  {message}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse gap-x-2">
            {actionLabel && (
              <Button
                type="button"
                color="red"
                // className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                onClick={() => {
                  setAction("accept");
                  onFinalize("accept");
                }}
              >
                {actionLabel}
              </Button>
            )}
            {cancelLabel && (
              <Button
                type="button"
                // className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                onClick={() => {
                  setAction("cancel");
                  onFinalize("cancel");
                }}
                ref={cancelButtonRef}
              >
                {cancelLabel}
              </Button>
            )}
          </div>
        </Fragment>
      </Dialog>
      {/* </Transition> */}
    </Provider>
  );
};
export default AlertProvider;

export const useAlert = () => {
  const {
    setTitle,
    setMessage,
    setOnFinalize,
    setShow,
    show,
    setActionLabel,
    setCancelLabel,
    setType,
  } = useContext(context);

  const alert = useCallback(
    async (options: {
      title: string | ReactElement;
      message: string | ReactElement;
      actionLabel?: string;
      cancelLabel?: string;
      type?: "alert" | "success" | "information";
    }) => {
      const { title, message, actionLabel, cancelLabel } = options;
      if (show) throw new Error("Cannot show an alert when other is displayed");
      setTitle(title);
      setMessage(message);
      setShow(true);
      setCancelLabel(cancelLabel);
      setActionLabel(actionLabel);
      setType(options.type || "alert");
      const p = new Promise<string>((resolve) => {
        setOnFinalize(() => resolve);
      });
      const result = await p;
      setShow(false);
      return result;
    },
    [
      setMessage,
      setOnFinalize,
      setTitle,
      show,
      setShow,
      setActionLabel,
      setCancelLabel,
    ],
  );
  const confirm = useCallback(
    async (options: {
      title: string | ReactElement;
      message: string | ReactElement;
      acceptLabel?: string;
      cancelLabel?: string;
    }) => {
      return alert({
        title: options.title,
        message: options.message,
        actionLabel: options.acceptLabel || "Confirm",
        cancelLabel: options.cancelLabel || "Cancel",
      });
    },
    [alert],
  );
  return { confirm, alert };
};
