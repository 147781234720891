import { FC, useEffect } from "react";
import { useAuthentication } from "./Authenticator";
import { Formik, Form } from "formik";
import Logo from "./Logo";
import { Link } from "react-router-dom";
import { Card } from "./components/ui/card";
import { Button } from "./components/ui/button";

const Login: FC = () => {
  const { loginWithPassword, loginWithCircle } = useAuthentication();
  useEffect(() => {
    loginWithCircle().catch((e) =>
      console.log("Could not login with circle", e)
    );
  }, [loginWithCircle]);
  return (
    <Formik
      initialValues={{ email: "", password: "" }}
      onSubmit={async ({ email, password }) => {
        await loginWithPassword(email, password);
      }}
    >
      {() => (
        <Form>
          <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
              <Logo className="mx-auto h-12 w-12" />
              <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-zinc-900 dark:text-white">
                <span className="font-medium text-zinc-500 dark:text-zinc-400">Sign in to</span>{" "}
                ScriptTag
              </h2>
              <p className="font-semibold text-center text-zinc-500 dark:text-zinc-400">
                by{" "}
                <a
                  className="text-zinc-900 dark:text-white hover:underline"
                  href="https://statechange.ai"
                >
                  State Change
                </a>
              </p>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <Card className="py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div className="mt-0">
                  <div className="relative">
                    <div className="justify-normal font-md text-sm mx-2 border border-zinc-200 dark:border-zinc-700 rounded-md shadow-md p-2 mb-6 mt-0 bg-zinc-100 dark:bg-zinc-900 text-zinc-600 dark:text-zinc-300">
                      ScriptTag is a service available to State Change members
                      and clients. Sign in with your State Change credentials,
                      or{" "}
                      <a
                        className="text-blue-500 hover:text-blue-600 dark:hover:text-blue-400 hover:underline transition-all duration-150"
                        href="https://statechange.ai"
                      >
                        join State Change
                      </a>{" "}
                      to access ScriptTag and make progress on your hardest 5%.
                    </div>
                  </div>
                  <div>
                    <Button
                      variant="default"
                      className="w-full"
                      asChild
                    >
                      <a
                        href={
                          "https://pro.statechange.ai/c/ask-for-help/?source=" +
                          encodeURIComponent(window.location.href)
                        }
                      >
                        Sign In With State Change
                      </a>
                    </Button>
                  </div>
                  <div>
                    <Link
                      to="/login-form"
                      className="mt-8 text-sm font-medium text-center text-zinc-500 dark:text-zinc-400 hover:text-zinc-600 dark:hover:text-zinc-300 w-full flex justify-center"
                    >
                      Sign In With Username/Email
                    </Link>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};
export default Login;
